<template>
  <div id="customer-list-list-view" class="customer-list-container">

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'Reports'">
    </breadcrumb-base>

    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
               class="mx-auto mb-4 max-w-full">
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div class="">

        <div class="flex flex-row">

          <div class="vx-col mt-3">
            <label style="font-size: 12px; font-weight: bold">{{ $t('Status') }}
              <span
                @click="selectedStatus = null;onFilterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <vs-select
              autocomplete
              class="pr-4 w-full pb-3"
              v-model="selectedStatus"
              @input="onFilterChanged"
              :placeholder="$t('Status')"
            >
              <vs-select-item :key="index" :value="f.value" :text="f.name"
                              v-for="(f,index) in status"/>
            </vs-select>
          </div>

          <div class="vx-col mt-3">
            <label style="font-size: 12px; font-weight: bold">{{ $t('Flag') }}
              <span
                @click="selectedFlag = null;onFilterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <vs-select
              autocomplete
              class="pr-4 w-full pb-3"
              v-model="selectedFlag"
              @input="onFilterChanged"
              :placeholder="$t('Flag')"
            >
              <vs-select-item :key="index" :value="f.id" :text="f.name" v-for="(f,index) in flags"/>
            </vs-select>
          </div>
          <div class="vx-col mt-3"
               v-if="showMangerDropDown">

            <label style="font-size: 12px; font-weight: bold">{{ $t('Manager') }}
              <span
                @click="selectedManager = null;onFilterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <vs-select
              autocomplete
              class="pr-2 w-full pb-3"
              v-model="selectedManager"
              @input="onFilterChanged(-1)"
              :placeholder="$t('Manager')"
            >
              <vs-select-item key="all_referer" :value="null" :text="$t('All')"/>
              <vs-select-item :key="index" :value="customer" :text="customer.user_name"
                              v-for="(customer,index) in usersRaw.manager_accounts"/>
            </vs-select>
          </div>

          <div class="vx-col mt-3"
               v-if="showRefererDropDown">

            <label style="font-size: 12px; font-weight: bold">{{ $t('Staff') }}
              <span
                @click="selectedReferer = null;onFilterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <vs-select
              autocomplete
              class="pr-2 w-full pb-3"
              v-model="selectedReferer"
              @input="onFilterChanged"
              :placeholder="$t('Staff')"
            >
              <vs-select-item key="all_referer" :value="null" :text="$t('All')"/>
              <vs-select-item :key="index" :value="customer.user_id" :text="customer.user_name"
                              v-for="(customer,index) in staffAccounts"/>
            </vs-select>
          </div>

          <div class="flex m-auto">
            <div class="">
              <vs-button @click="clearAllFilters">{{ $t('ClearAll') }}</vs-button>
            </div>
          </div>

          <vs-spacer></vs-spacer>
        </div>

        <div class="flex flex-row justify-between w-full space-x-2">
          <div class="flex flex-row space-x-4 items-end justify-end">
          </div>
          <div class="flex flex-row items-center space-x-4">
            <div
              class="con-input-search vs-table--search">
              <!-- @input="searchDebounce"-->
              <vs-input
                class="input-search"
                :disabled="isSearching"
                v-model="searchText"
                type="text"
                icon-no-border icon="search"/>
            </div>

            <vs-button @click="searchCustomers()">{{ $t('Search') }}</vs-button>

            <div class="flex flex-row items-center space-x-2" >
              <vs-button @click="viewAllCustomers()">{{ viewAll ? $t('ShowLess') : $t('ViewAll') }}</vs-button>
<!--              <template v-if="customers.length > 0">-->
<!--                <vs-button @click="onOpenDownloadEmailModal(!true)">{{ $t('DownloadCSV') }}</vs-button>-->
<!--                <vs-button @click="onOpenDownloadEmailModal(!false)">{{ $t('DownloadExcel') }}</vs-button>-->
<!--              </template>-->
            </div>
          </div>
        </div>
        <div class="w-full flex flex-row">

          <div class="flex flex-col md:w-1/5 mt-4">
            <vx-card>

              <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                         @click="clearFilterData">{{ $t('ClearAllFilters').toUpperCase() }}
              </vs-button>
              <vs-divider/>
              <p>
                <span class=" text-xl">{{ $t('Column1') }}</span>
              </p>
              <div class="flex flex-col space-y-4 items-start justify-start">
                <div class="flex flex-col">
                  <label style="font-size: 12px; font-weight: bold">{{ $t('FromDate') }}</label>
                  <datepicker :placeholder="$t('FromDate')"
                              :disabled-dates="{from: new Date()}"
                              v-model="startDate"></datepicker>
                </div>
                <div class="flex flex-col">
                  <label style="font-size: 12px; font-weight: bold">{{ $t('ToDate') }}</label>
                  <datepicker :placeholder="$t('ToDate')"
                              v-model="endDate" :disabled-dates="disabledDates"></datepicker>
                </div>
              </div>
              <div class="py-2"></div>
              <vs-divider/>
              <p>
                <span class="fot-bold text-xl">{{ $t('Column2') }}</span>
              </p>
              <div class="flex flex-col space-y-4 items-start justify-start">
                <div class="flex flex-col">
                  <label style="font-size: 12px; font-weight: bold">{{ $t('FromDate') }}</label>
                  <datepicker :placeholder="$t('FromDate')"
                              :disabled-dates="{from: new Date()}"
                              v-model="startDate2"></datepicker>
                </div>
                <div class="flex flex-col">
                  <label style="font-size: 12px; font-weight: bold">{{ $t('ToDate') }}</label>
                  <datepicker :placeholder="$t('ToDate')"
                              v-model="endDate2" :disabled-dates="disabledDates2"></datepicker>
                </div>
              </div>
              <div class="py-2"></div>

              <div class="vs-col w-full"
                   v-if="productsFilter.length > 0">
                <label style="font-size: 12px; font-weight: bold">{{ $t('Product') }}
                  <span
                    @click="selectedProduct = null;salesFilterChanged()"
                    class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
                </label>
                <vs-select
                  autocomplete
                  class="pr-4 w-full"
                  v-model="selectedProduct"
                  v-if="productsFilter.length > 0"
                  @input="onProductFilterChanged"
                >

                  <vs-select-item :key="index" :value="product"
                                  :text="product.product_name"
                                  v-for="(product,index) in productsFilter"/>
                </vs-select>
                <div class="py-2"></div>
              </div>
              <div class="vs-col mb-4 w-full"
                   v-if="productsFilter.length > 0 && selectedProduct">
                <label style="font-size: 12px; font-weight: bold">{{ $t('Denomination') }}
                  <span
                    @click="selectedProductConfigId = null;filterChanged()"
                    class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
                </label>
                <vs-select
                  autocomplete
                  class="pr-4 w-full"
                  v-model="selectedProductConfigId"
                  v-if="productsFilter.length > 0"
                  @input="onProductFilterChanged"
                >
                  <template v-for="(product) in selectedProduct.configurations">

                    <vs-select-item
                      :value="product.product_configuration_id + 'product_configuration_id'"
                      :text="product.product_configuration_value | germanNumberFormat"/>
                  </template>
                </vs-select>
              </div>
              <div class="py-4"></div>
              <div class="vx-col mt-4 m-3"
                   :key="index"
                   v-for="(item, index) in Object.keys(salesFilters)">
                <label style="font-size: 12px; font-weight: bold">
                  {{ item | title }}
                  <span
                    @click="filterSelectData[item] = null;salesFilterChanged()"
                    class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
                </label>
                <ul class="centerx mt-4">
                  <li
                    :key="index"
                    v-for="(option, index) in salesFilters[item]">
                    <vs-radio v-model="filterSelectData[item]" v-on:change="salesFilterChanged"
                              :vs-value="option.id.toString() + item">{{ option.name | title }}
                    </vs-radio>
                  </li>
                </ul>
                <vs-divider/>
              </div>
              <div class="py-2"></div>


              <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                         @click="salesFilterChanged">{{ $t('Filter').toUpperCase() }}
              </vs-button>
              <div class="py-2"></div>
              <!--            <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"-->
              <!--                       @click="clearFilterData">{{ $t('ClearAllFilters').toUpperCase() }}-->
              <!--            </vs-button>-->
            </vx-card>
          </div>
          <div class="flex flex-col md:w-4/5">
            <vs-table-modified v-show="!errorFetching" ref="table"
                               :max-items="itemsPerPage"
                               :data="customers">


              <template slot="header">
              </template>

              <template slot="thead">
                <vs-th>
                  <p style="display: inline-flex;">
                    <span style="line-height: 24px;">{{ $t('Reference') }}</span>
                    <span class="material-icons" v-if="sortKey === 'by_refer' && sortOrder === 0">
                arrow_drop_down
                </span>
                    <span class="material-icons" v-if="sortKey === 'by_refer' && sortOrder == 1">
                arrow_drop_up
                </span>
                  </p>
                </vs-th>
                <vs-th>
                  <p style="display: inline-flex;">
                    <span style="line-height: 24px;">{{ $t('ID') }}</span>
                    <span class="material-icons"
                          v-if="sortKey === 'by_customer_id' && sortOrder == 0">
                arrow_drop_down
                </span>
                    <span class="material-icons"
                          v-if="sortKey === 'by_customer_id' && sortOrder == 1">
                arrow_drop_up
                </span>
                  </p>
                </vs-th>
                <vs-th>
                  <p style="display: inline-flex;">
                    <span style="line-height: 24px;">{{ $t('Name') }}</span>
                    <span class="material-icons" v-if="sortKey === 'by_name' && sortOrder == 0">
                arrow_drop_down
                </span>
                    <span class="material-icons" v-if="sortKey === 'by_name' && sortOrder == 1">
                arrow_drop_up
                </span>
                  </p>
                </vs-th>
                <vs-th>
                  <div class="vs-col">
                    <p class="">{{ $t('City') }}</p>
                    <p class="">{{ $t('PostalCode') }}</p>
                  </div>
                </vs-th>
                <vs-th>
                  <div style="display: inline-flex;"
                     @click="localSortUpdate('by_total_buying_amount')">
                      <div class="vs-col">
                        <p>{{ $t('TotalSaleAmount') }}</p>
                        <p>{{ datesHeading1 }}</p>
                      </div>
                    <span class="material-icons"
                          v-if="localSortKey === 'by_total_buying_amount' && localSortOrder == 0">
                      arrow_drop_down
                    </span>
                    <span class="material-icons"
                          v-if="localSortKey === 'by_total_buying_amount' && localSortOrder == 1">
                      arrow_drop_up
                    </span>
                  </div>
                </vs-th>
                <vs-th>
                  <div style="display: inline-flex;" @click="localSortUpdate('by_total_sale_amount')">
                  <div class="vs-col">
                    <p>{{ $t('TotalSaleAmount') }}</p>
                    <p>{{ datesHeading2 }}</p>
                  </div>
                    <span class="material-icons"
                          v-if="localSortKey === 'by_total_sale_amount' && localSortOrder == 0">
                      arrow_drop_down
                    </span>
                    <span class="material-icons"
                          v-if="localSortKey === 'by_total_sale_amount' && localSortOrder == 1">
                      arrow_drop_up
                    </span>
                  </div>
                </vs-th>
                <vs-th>
                  <p style="display: inline-flex;"
                     @click="localSortUpdate('by_difference')">
                    <span style="line-height: 24px;">{{ $t('Difference') }}</span>
                    <span class="material-icons"
                          v-if="localSortKey === 'by_difference' && localSortOrder == 0">
                      arrow_drop_down
                    </span>
                    <span class="material-icons"
                          v-if="localSortKey === 'by_difference' && localSortOrder == 1">
                      arrow_drop_up
                    </span>
                  </p>
                </vs-th>
                <vs-th>
                  <p style="display: inline-flex;"
                     @click="localSortUpdate('by_difference_percentage')">
                    <span style="line-height: 24px;">{{ $t('DifferencePercentage') }}</span>
                    <span class="material-icons"
                          v-if="localSortKey === 'by_difference_percentage' && localSortOrder == 0">
                      arrow_drop_down
                    </span>
                    <span class="material-icons"
                          v-if="localSortKey === 'by_difference_percentage' && localSortOrder == 1">
                      arrow_drop_up
                    </span>
                  </p>
                </vs-th>
              </template>

              <template slot-scope="{data}">
                <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                  <vs-td>
                    <p
                      class="font-medium truncate px-4">{{ tr.refer }}</p>
                  </vs-td>
                  <vs-td>
                    <vx-tooltip>
                      <template slot="tip">
                        <p v-if="tr.remarks ">{{ $t('Remark') }}: <strong>{{ tr.remarks }}</strong>
                        </p>
                      </template>
                      <div class="vs-col">
                        <p class="font-medium truncate flex">
                        <span v-if="tr.remarks" :id="tr.customer_id"
                              @dblclick="copyToClipboard(tr.customer_id)"
                              style="color: rgb(var(--vs-primary));font-weight: bold;">{{
                            tr.customer_id
                          }}</span>
                          <span v-else style="font-weight: bold;" :id="tr.customer_id"
                                @dblclick="copyToClipboard(tr.customer_id)">{{
                              tr.customer_id
                            }}</span>
                        </p>
                      </div>
                    </vx-tooltip>

                  </vs-td>
                  <vs-td>
                    <vx-tooltip>
                      <template slot="tip">
                        <p>{{ $t('Organisation') }}: <strong>{{ tr.organisation_name }}</strong></p>
                        <!--                  <p>{{ $t('LastActive') }}: <strong>{{ tr.activated_on | dateFormat }}</strong></p>-->
                      </template>
                      <div class="flex flex-col">
                        <p class="font-medium truncate"
                           style="max-width: 150px;white-space: normal !important;padding: 0 8px;">
                          {{ tr.company }}
                        </p>
                        <p class="subtitle" style="font-size: 12px;">
                          {{ tr.address }}<span v-if="tr.house_no"> {{ tr.house_no }}</span>
                        </p>
                      </div>
                    </vx-tooltip>
                  </vs-td>
                  <vs-td>
                    <div class="vs-col">
                      <p class="font-medium truncate">{{ tr.city }}</p>
                      <p class="font-medium truncate">{{ tr.postal_code }}</p>
                    </div>
                  </vs-td>
                  <vs-td>
                    <p v-if="(tr.total_sale_amount !== undefined && tr.total_sale_amount != null) || !fetchingSalesData">
                      {{ tr.total_sale_amount | germanNumberFormat }}
                    </p>
                    <p v-else>
                      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </p>
                  </vs-td>
                  <vs-td>
                    <p v-if="(tr.total_sale_amount_2 !== undefined && tr.total_sale_amount_2 != null) || !fetchingSalesData">
                      {{ tr.total_sale_amount_2 | germanNumberFormat }}
                    </p>
                    <p v-else>
                      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </p>
                  </vs-td>
                  <vs-td>
                    <p
                      :class="{
                        'text-success': tr.difference > 0,
                        'text-danger': tr.difference < 0
                      }" >
                      {{ tr.difference | germanNumberFormat }}
                    </p>
                  </vs-td>
                  <vs-td>
                    <p
                      :class="{
                        'text-success': tr.difference > 0,
                        'text-danger': tr.difference < 0
                      }" >
                      <span >{{ tr.difference_percentage }}%</span>
                    </p>
                  </vs-td>
                </vs-tr>
                </tbody>
              </template>
              <template slot="footer">
              </template>
              <template slot="footer-left">
                <div class="flex">
                  <vs-new-pagination :total="vTotalPages" v-model="currentPage"
                                     v-on:change="listPageChanged">
                  </vs-new-pagination>
                </div>
              </template>
            </vs-table-modified>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>

import Datepicker from 'vuejs-datepicker'
import VxModal from "@/layouts/components/custom/VxModal";
import CustomerProjectsComponent
  from "@/views/pages/superadmin/customer/components/CustomerProjectsComponent";
import moment from "moment";
import axios from 'axios'

export default {
  name: 'SalesComparisionReport',
  components: {CustomerProjectsComponent, VxModal, Datepicker,},
  data() {
    return {
      customerIndex: 0,
      searchText: '',
      itemsPerPage: 20,
      isSearching: false,
      isMounted: false,
      errorFetching: false,
      selectedManager: null,
      selectedReferer: null,
      selectedFlag: null,
      selectedStatus: true,
      selectedCreditSort: null,
      selectedBalanceSort: null,
      customer: null,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'SalesComparison', i18n: 'SalesComparison', active: true},
      ],
      flags: [
        {
          id: 0,
          name: 'Red',
        },
        {
          id: 1,
          name: 'Green',
        },
        {
          id: 2,
          name: 'Yellow',
        },
      ],
      currentPage: 1,
      viewAll: false,
      sortKey: '',
      sortOrder: -1,
      status: [
        {
          name: 'All',
          value: null,
        },
        {
          name: 'Active',
          value: true,
        },
        {
          name: 'Deactivate',
          value: false,
        },
      ],
      productsFilter: {},
      filterSelectData: {},
      selectedProduct: null,
      selectedProductConfigId: null,
      startDate: new Date(),
      endDate: new Date(),
      startDate2: new Date(),
      endDate2: new Date(),
      cancelRequest: null,
      datesHeading1: "",
      datesHeading2: "",
      fetchingSalesData: false,
      localSortKey: '',
      localSortOrder: -1,
    }
  },
  computed: {

    disabledDates() {
      if (!this.startDate) {
        return {
          from: new Date(),
        }
      }
      return {
        to: this.startDate,
        from: new Date(),
      }
    },
    disabledDates2() {
      if (!this.startDate2) {
        return {
          from: new Date(),
        }
      }
      return {
        to: this.startDate2,
        from: new Date(),
      }
    },
    vPage() {
      return this.$store.state.salesReport2.vPage
    },
    vTotalCount() {
      return this.$store.state.salesReport2.vTotalCount
    },
    vTotalPages() {
      return this.$store.state.salesReport2.vTotalPages
    },
    usersRaw() {
      return this.$store.state.user.usersRaw || {}
    },
    customers() {
      const data = []
      data.push(...this.$store.state.salesReport2.customers)
      if (!this.fetchingSalesData) {
        if (this.localSortKey === "by_total_buying_amount" && this.localSortOrder !== -1) {
          data.sort((a, b) => {
            return this.localSortOrder === 0 ? a.total_buying_amount - b.total_buying_amount : b.total_buying_amount - a.total_buying_amount
          });
        }
        if (this.localSortKey === "by_total_sale_amount" && this.localSortOrder !== -1) {
          data.sort((a, b) => {
            return this.localSortOrder === 0 ? a.total_sale_amount - b.total_sale_amount : b.total_sale_amount - a.total_sale_amount
          });
        }
      }
      return data;
    },
    salesData() {
      return this.$store.state.salesReport2.salesData
    },
    customersRaw() {
      return this.$store.state.salesReport2.customersRaw
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.customers.length
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    users() {
      return this.$store.state.user.users
    },
    staffAccounts() {
      if (this.selectedManager != null) {
        return this.selectedManager.staff_accounts
      } else {
        return this.usersRaw.staff_accounts;
      }
    },
    showMangerDropDown() {
      return this.activeUserInfo.internal_role === 0;
    },
    showRefererDropDown() {
      return this.activeUserInfo.internal_role === 0 || this.activeUserInfo.staff_role === 1;
    },

    salesRaw() {
      return this.$store.state.reports.salesRaw
    },
    salesFilters() {
      return this.$store.state.salesReport2.salesFilters
    },
  },
  methods: {
    copyToClipboard(id) {
      var r = document.createRange();
      r.selectNode(document.getElementById(id));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(r);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
    },
    localSortUpdate(key) {
      if (key === this.localSortKey) {
        if (this.localSortOrder == -1) {
          this.localSortOrder = 0;
        } else if (this.localSortOrder == 0) {
          this.localSortOrder = 1;
        } else if (this.localSortOrder == 1) {
          this.localSortOrder = -1;
        }
      } else {
        this.localSortOrder = 0
      }
      this.localSortKey = key;
    },
    updateSort(key) {
      if (key === this.sortKey) {
        if (this.sortOrder == -1) {
          this.sortOrder = 0;
        } else if (this.sortOrder == 0) {
          this.sortOrder = 1;
        } else if (this.sortOrder == 1) {
          this.sortOrder = -1;
        }
      } else {
        this.sortOrder = 0
      }
      this.sortKey = key;
      this.loadCustomer('0', this.searchText)
    },
    textColor(flag) {
      if (flag == 0) {
        return 'text-danger'
      }
      if (flag == 1) {
        return 'text-success'
      }
      if (flag == 2) {
        return 'text-warning'
      }
      return flag ? 'text-primary' : 'text-grey'
    },
    getStatusColor(status) {
      if (status === 1) return 'success'
      return 'warning'
    },
    getStatusText(status) {
      if (status === 1) return 'Active'
      return 'In Active'
    },
    onFilterChanged(resetRefer) {
      if (resetRefer === -1) {
        this.selectedReferer = null
      }
      this.loadCustomer()
    },
    clearAllFilters() {
      this.errorFetching = false
      this.selectedManager = null
      this.selectedReferer = null
      this.selectedFlag = null
      this.selectedStatus = null
      this.loadCustomer()
    },
    searchDebounce() {
      clearTimeout(this.interval)
      this.interval = setTimeout(() => {
        this.isSearching = true
        this.loadCustomer('0', this.searchText)
      }, 500)
    },
    viewAllCustomers() {
      this.viewAll = !this.viewAll;
      this.loadCustomer('0');
    },
    searchCustomers() {
      this.loadCustomer('0', this.searchText)
    },
    listPageChanged(item) {
      this.loadCustomer(this.currentPage - 1)
    },
    loadReferer() {
      this.$vs.loading()
      this.$store.dispatch('user/fetchUsers', {})
        .then((data) => {
          this.errorFetching = false
        })
        .catch((err) => {
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    getFilters() {
      const p = {}
      if (this.selectedFlag !== null && this.selectedFlag !== undefined) {
        p.flag = this.selectedFlag
      }
      if (this.selectedStatus !== null && this.selectedStatus !== undefined) {
        p.is_active = this.selectedStatus
      }
      if (this.selectedManager) {
        p.refer_id = this.selectedManager.user_id
      }
      if (this.selectedReferer) {
        p.refer_id = this.selectedReferer
      }
      if (this.selectedCreditSort) {
        p.sort_by_credit = this.selectedCreditSort
      }
      if (this.selectedBalanceSort) {
        p.sort_by_balance = this.selectedBalanceSort
      }
      if (this.sortKey !== '' && this.sortOrder !== -1) {
        p[this.sortKey] = this.sortOrder;
      }
      if(this.viewAll) {
        p['view_all'] = this.viewAll ? 1 : 0;
      }
      return p
    },
    performDownloadCustomers(isCSV = false) {
      this.downloadCustomers(0, this.searchText, isCSV)
    },
    loadCustomer(page = '0') {
      if(this.cancelRequest) {
        this.cancelRequest.cancel("reset-cancel");
        this.$store.dispatch('salesReport2/resetCustomersSalesData');
        this.fetchingSalesData = false;
      }

      if (page == 0) this.currentPage = 1
      const filters = this.getFilters()
      this.$vs.loading()
      this.$store.dispatch('salesReport2/fetchReportCustomers', {
        filters,
        page,
        search: this.searchText,
      })
        .then((data) => {
          this.$vs.loading.close()
          this.isSearching = false
          this.errorFetching = false
          if(this.cancelRequest) {
            this.cancelRequest.cancel("reset-cancel");
            this.$store.dispatch('salesReport2/resetCustomersSalesData');
            this.fetchingSalesData = false;
            if(this.startDate && this.endDate && this.startDate2 && this.endDate2) {
              this.salesFilterChanged();
            }
          }
          this.customerIndex = 0;
          // this.updateData();
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.isSearching = false
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-cixrcle',
            color: 'danger',
          })
        })
    },

    onProductFilterChanged(resetRefer) {
      if (resetRefer === -1) {
        this.selectedReferer = null
      }
      this.salesFilterChanged()
    },
    salesFilterChanged() {
      if(this.cancelRequest) {

        this.fetchingSalesData = false;
        this.cancelRequest.cancel();
        this.$store.dispatch('salesReport2/resetCustomersSalesData');
      }
      this.customerIndex = 0;
      this.updateData();

      let isSameData1 = this.startDate.getFullYear() === this.endDate.getFullYear() &&
        this.startDate.getMonth() === this.endDate.getMonth() &&
        this.startDate.getDate() === this.endDate.getDate();
      if(isSameData1) {
        const startString = this.$options.filters.onlyDateFormat(this.startDate);
        this.datesHeading1 = `${startString}`;
      } else {
        const startString = this.$options.filters.onlyDateFormat(this.startDate);
        const endString = this.$options.filters.onlyDateFormat(this.endDate);
        this.datesHeading1 = `${startString} - ${endString}`;
      }
      let isSameData2 = this.startDate2.getFullYear() === this.endDate2.getFullYear() &&
        this.startDate2.getMonth() === this.endDate2.getMonth() &&
        this.startDate2.getDate() === this.endDate2.getDate();
      if(isSameData2) {
        const startString = this.$options.filters.onlyDateFormat(this.startDate2);
        this.datesHeading2 = `${startString}`;
      } else {
        const startString = this.$options.filters.onlyDateFormat(this.startDate2);
        const endString = this.$options.filters.onlyDateFormat(this.endDate2);
        this.datesHeading2 = `${startString} - ${endString}`;
      }
      // datesHeading1 = this.getDatesHeading();
      // datesHeading2 = this.getDatesHeading();
    },

    clearFilterData() {
      this.filterSelectData = {}
      this.selectedProduct = null
      this.selectedProductConfigId = null
      this.salesFilterChanged()
    },
    clearFilter(key) {
      this.filterSelectData[key] = null
      this.salesFilterChanged()
    },
    updateData() {
      let filters = this.getSalesFilters();
      // if(this.customerIndex == -1 || this.customerIndex == 0) {
      //   this.$store.dispatch('salesReport2/resetCustomersSalesData');
      //   this.customerIndex = 0;
      // }
      if(!this.customers[this.customerIndex]) {
        this.fetchingSalesData = false;
        return
      }
      this.cancelRequest = axios.CancelToken.source();
      filters.customer_id = this.customers[this.customerIndex].customer_id

      this.fetchingSalesData = true;
      this.$store.dispatch('salesReport2/getSalesReport2DataWithCustomers', {
        filters,
        cancelToken: this.cancelRequest.token
      }).then((data) => {
          // this.customerIndex += 1;
        this.updateData2();
      })
    },
    updateData2() {
      let filters = this.getSalesFilters(2);
      // if(this.customerIndex == -1 || this.customerIndex == 0) {
      //   this.$store.dispatch('salesReport2/resetCustomersSalesData');
      //   this.customerIndex = 0;
      // }
      if(!this.customers[this.customerIndex]) {
        return
      }
      this.cancelRequest = axios.CancelToken.source();
      filters.customer_id = this.customers[this.customerIndex].customer_id

      this.$store.dispatch('salesReport2/getSalesReport2DataWithCustomers', {
        filters,
        cancelToken: this.cancelRequest.token,
        type: 2
      }).then((data) => {
          this.customerIndex += 1;
          if (this.customerIndex < this.customers.length) {
            this.updateData();
          } else {

            this.fetchingSalesData = false;
          }
      })
    },
    //  Sales Filters
    getSalesFilters(type) {
      const filter = {}
      Object.keys(this.filterSelectData).forEach((key) => {
        if (this.filterSelectData[key] != null) {
          filter[key] = this.filterSelectData[key].replace(key, '')
        }
      })
      if(type == 2) {
        if (this.startDate) {
          filter.start_date = moment(this.startDate2).format('yyyy-MM-DD')
        }
        if (this.endDate) {
          filter.end_date = moment(this.endDate2).format('yyyy-MM-DD')
        }
      } else {
        if (this.startDate) {
          filter.start_date = moment(this.startDate).format('yyyy-MM-DD')
        }
        if (this.endDate) {
          filter.end_date = moment(this.endDate).format('yyyy-MM-DD')
        }
      }

      if (this.selectedProduct) {
        filter.product_id = this.selectedProduct.product_id
      }
      if (this.selectedProductConfigId) {
        filter.product_configuration_id = this.selectedProductConfigId.replace('product_configuration_id', '')
      }
      return filter
    },
    fetchProductsFilter() {
      this.$store.dispatch('reports/fetchProductFilters').then((data) => {
        if (data.message_type === 1) {
          try {
            this.productsFilter = data.type_of_filters.products
          } catch (e) {
            this.productsFilter = []
          }
        }
      })
    },
  },
  created() {
    // this.loadCustomer()
    this.loadReferer()
    this.fetchProductsFilter();
  },
  beforeDestroy() {
    if (this.cancelRequest) {
      this.cancelRequest.cancel();
    }
  },
  mounted() {
    this.isMounted = true
  },
}
</script>

<style lang="scss">
.small-c-button {
  width: 20px !important;
  height: 20px !important;
}


.header-table {
  width: auto;

  th {
    font-weight: normal;
    font-size: 14px;
    min-width: 200px;
  }

  td {
    font-weight: bold;
    font-size: 24px;
    color: rgba(var(--vs-primary), 1);
  }
}

#customer-list-list-view {
  .product-image {
    /*max-width: 200px;*/
    max-height: 40px;
    object-fit: cover;
  }

  .supplier-image {
    /*max-width: 200px;*/
    max-height: 40px;
    object-fit: cover;
  }

  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .customer-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .supplier-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 .2rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 8px 2px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }

  .subtitle {
    font-size: 10px;
    color: #9c9c9c;
  }
}

.material-icons {
  display: inline-flex;
  vertical-align: top;
}

.table-mobile-customers {
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 2rem 0;
  }

  /* Zebra striping */
  tr:nth-of-type(odd) {
    background: #eee;
  }

  tr {
    margin: 10px;

    th:first-child {
      width: 140px;
    }
  }

  //th {
  //  background: #333;
  //  color: white;
  //  font-weight: bold;
  //}
  td, th {
    padding: 6px;
    border: 1px solid #ccc;
    text-align: left;
  }
}
</style>
